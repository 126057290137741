.container {
  height: 100%;
  display: flex;
  justify-content: space-evenly;

  .receiptList {
    padding-top: 10%;
    width: 50%;
    text-align: center;

    display: grid;
    grid-template-rows: 3fr 1fr;

    li {
      padding-bottom: 15px;
    }
  }
}

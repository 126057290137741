.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .navContainer {
    display: flex;

    & > * {
      width: 200px;
      height: 200px;
    }
  }
}

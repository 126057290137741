.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  font-size: 1.5em;

  .container {
    width: 75%;
    height: 90%;
    background-color: blanchedalmond;

    .dealList {
      padding: 0 20px;

      & > li {
        margin-top: 25px;
      }
    }
  }
}

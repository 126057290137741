.headerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: auto;

  padding: 15px 5px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.wrapper {
  width: 100vw;
  height: 100vh;

  .gameContainer {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    .chalkBoard {
      background-color: rgb(44, 44, 49);
      color: rgb(209, 209, 206);
      font-family: "Gochi Hand", cursive;
      font-size: 1.2em;

      .gameWonContainer {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > * {
          font-size: 4em;
        }
      }
    }
  }

  .infoContainer {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    .resetContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      & > * {
        width: 100px;
        height: 50px;
      }
    }

    .lowerBar {
      display: flex;
      justify-content: space-evenly;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          font-size: 1.5em;
        }
      }

      .grandTotalContainer {
        p {
          font-size: 2em;
        }
      }
    }
  }
}

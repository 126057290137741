.container {
  height: 75px;

  margin: 5px 0;
  border-top: rgb(126, 126, 126) 1px solid;

  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: auto;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
